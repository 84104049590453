import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

const app = createApp(App);

//自定义指令
import defineDirective from "@/utils/directives";

// 引入element-plus
import ElementPlus from "element-plus";
import "./assets/styles/element/index.scss";
import "element-plus/theme-chalk/display.css";
// import "element-plus/dist/index.css";
app.use(ElementPlus);
//注册所有element图标
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// 引入样式文件
import "./assets/styles/reset.scss";
// import "./assets/styles/media.scss";
import "./assets/styles/knowledge/index.scss";
//引入阿里巴巴字体图标库
import "./assets/styles/iconfont.scss"; //此文件只在开发环境下引用
// import "./assets/style/font/iconfont.scss";//此文件在线上环境下引用，

app.use(store).use(router).use(defineDirective).mount("#app");
