import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/test",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/Test.vue"),
  },
  {
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/",
    name: "Layout",
    redirect: "/search",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/Layout.vue"),
    children: [
      /**搜索页 */
      {
        path: "/search",
        name: "Search",
        component: () =>
          import(/* webpackChunkName: "search" */ "../views/Search.vue"),
      },
      /**图谱页 */
      {
        path: "/graph",
        name: "Graph",
        component: () =>
          import(/* webpackChunkName: "graph" */ "../views/Graph.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
