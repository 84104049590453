<template>
  <router-view v-if="isRouterAlive" />
</template>

<script setup>
import { ref, provide, nextTick } from "vue";
/**重新加载页面 */
const isRouterAlive = ref(true);
provide("reload", () => {
  isRouterAlive.value = false;
  nextTick(() => {
    isRouterAlive.value = true;
  });
});
</script>

<style lang="scss">
.el-message {
  min-width: 400px;
  text-align: center;
}
.el-message__content {
  width: calc(100% - 22px);
}

#app {
  font-size: 14px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #101010;
}
</style>
