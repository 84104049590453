import { createStore } from "vuex";

export default createStore({
  state: {
    ifDetailVisible: true, //是否显示右侧详情卡片
    idForDetailCard: "", // 当前右侧卡片展示的实体/关系的id
    typeForDetailCard: "", // 当前右侧卡片展示的实体/关系的类型
  },
  mutations: {
    // 显示右侧卡片
    handleDetailShow(state) {
      state.ifDetailVisible = true;
    },
    // 隐藏右侧卡片
    handleDetailDisappear(state) {
      state.ifDetailVisible = false;
    },
    // 更新右侧卡片展示的实体/关系的id
    handleIdForDetailCardChange(state, newId) {
      state.idForDetailCard = newId;
    },
    // 更新右侧卡片展示的实体/关系的type
    handletTypeForDetailCardChange(state, newType) {
      state.typeForDetailCard = newType;
    },
  },
  actions: {},
  modules: {},
});
